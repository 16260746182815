import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { CopyToClipboardModule } from './directives/copy-to-clipboard.module';
import { ScrollToDirective } from './directives/scroll-to.directive';

import { CmpContentAreaGestioComponent } from './components/areas/content-area/cmp-content-area.component';
import { CmpDetailSectionGestioComponent } from './components/areas/detail-section/cmp-detail-section.component';
import { FirstStepsContentComponent } from './components/areas/first-steps-content/first-steps-content.component';
import { CmpFullPageAreaGestioComponent } from './components/areas/full-page-area/cmp-full-page-area.component';
import { CmpPageAreaGestioComponent } from './components/areas/page-area/cmp-page-area.component';
import { AttachFilesProductComponent } from './components/attach-files-product/attach-files-product.component';
import { CmpDetailSectionNavigationGestioComponent } from './components/detail-section-navigation/cmp-detail-section-navigation.component';
import { CmpTitleAreaGestioComponent } from './components/title-area/cmp-title-area.component';

import { AttachFilesModule } from './components/attach-files/attach-files.module';
import { ButtonsComponentsGestioModule } from './components/buttons/buttons-components.module';
import { ChartComponentsGestioModule } from './components/chart/chart-components.module';
import { CheckboxesComponentsGestioModule } from './components/checkbox/checkboxes-components.module';
import { DatagridComponentsGestioModule } from './components/datagrid/datagrid-components.module';
import { DateboxsComponentsGestioModule } from './components/datebox/dateboxs-components.module';
import { FooterComponentsGestioModule } from './components/footer/footer-components.module';
import { FormModalModule } from './components/modal/form/form-modal.module';
import { FullScreenModalModule } from './components/modal/full-screen/full-screen-modal.module';
import { NumberboxsComponentsGestioModule } from './components/numberbox/numberboxs-components.module';
import { RadioGroupComponentsGestioModule } from './components/radio-group/radio-group-components.module';
import { SelectboxsComponentsGestioModule } from './components/selectbox/selectboxs-components.module';
import { SwitchesComponentsGestioModule } from './components/switch/switches-components.module';
import { TabsGestioModule } from './components/tabs/tabs.module';
import { TextareasComponentsGestioModule } from './components/textarea/textareas-components.module';
import { TextboxGenerateProductCodeGestioModule } from './components/textbox-generate-product-code/textbox-generate-product-code.module';
import { TextboxSearchGestioModule } from './components/textbox-search/textbox-search.module';
import { TextboxsComponentsGestioModule } from './components/textbox/textboxs-components.module';

import { MessageNoRegisterModule } from '../ui-components/components/panels/message-no-register/message-no-register.module';
import { CommentsComponentsGestioModule } from './components/comments/comments-components.module';
import { TagboxComponentsGestioModule } from './components/tagbox/tagbox-components.module';
import { SharedUIModule } from './shared-ui.module';

@NgModule({
    imports: [RouterModule, SharedUIModule],
    declarations: [
        AttachFilesProductComponent,
        CmpContentAreaGestioComponent,
        CmpDetailSectionGestioComponent,
        CmpDetailSectionNavigationGestioComponent,
        CmpFullPageAreaGestioComponent,
        CmpPageAreaGestioComponent,
        CmpTitleAreaGestioComponent,
        FirstStepsContentComponent,
        ScrollToDirective,
    ],
    exports: [
        AttachFilesModule,
        AttachFilesProductComponent,
        ButtonsComponentsGestioModule,
        ChartComponentsGestioModule,
        CheckboxesComponentsGestioModule,
        CommentsComponentsGestioModule,
        MessageNoRegisterModule,
        CopyToClipboardModule,
        CmpContentAreaGestioComponent,
        CmpDetailSectionGestioComponent,
        CmpDetailSectionNavigationGestioComponent,
        CmpFullPageAreaGestioComponent,
        CmpPageAreaGestioComponent,
        CmpTitleAreaGestioComponent,
        DatagridComponentsGestioModule,
        DateboxsComponentsGestioModule,
        FirstStepsContentComponent,
        FooterComponentsGestioModule,
        FormModalModule,
        FullScreenModalModule,
        NumberboxsComponentsGestioModule,
        RadioGroupComponentsGestioModule,
        ScrollToDirective,
        SelectboxsComponentsGestioModule,
        SwitchesComponentsGestioModule,
        TagboxComponentsGestioModule,
        TabsGestioModule,
        TextareasComponentsGestioModule,
        TextboxGenerateProductCodeGestioModule,
        TextboxSearchGestioModule,
        TextboxsComponentsGestioModule,
    ],
})
export class UIComponentsGestioModule {}
