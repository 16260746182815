import { NgModule } from '@angular/core';
import { DxNumberBoxModule } from 'devextreme-angular';
import { SharedUIModule } from '../../shared-ui.module';
import { NumberboxDisabledLabelGestioComponent } from './numberbox-disabled-label/numberbox-disabled-label.component';
import { NumberboxLabelGestioComponent } from './numberbox-label/cmp-numberbox-label.component';
import { NumberboxSpinLabelGestioComponent } from './numberbox-spin-label/cmp-numberbox-spin-label.component';

@NgModule({
    imports: [SharedUIModule, DxNumberBoxModule],
    declarations: [
        NumberboxLabelGestioComponent,
        NumberboxDisabledLabelGestioComponent,
        NumberboxSpinLabelGestioComponent,
    ],
    exports: [
        NumberboxLabelGestioComponent,
        NumberboxDisabledLabelGestioComponent,
        NumberboxSpinLabelGestioComponent,
    ],
})
export class NumberboxsComponentsGestioModule {}
