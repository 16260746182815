import { NgModule } from '@angular/core';
import { SharedUIModule } from '../../../../ui-components-gestio/shared-ui.module';
import { FullScreenModalComponent } from './full-screen-modal.component';

@NgModule({
    imports: [SharedUIModule],
    declarations: [FullScreenModalComponent],
    exports: [FullScreenModalComponent],
})
export class FullScreenModalModule {}
