import { NgModule } from '@angular/core';

import { SharedUIModule } from '../../shared-ui.module';
import { ActionButtonComponent } from './action-button/action-button.component';
import { AddButtonComponent } from './add-button/add-button.component';
import { CommentsOptionButtonComponent } from './comments-option-button/comments-option-button.component';
import { DangerButtonComponent } from './danger-button/danger-button.component';
import { FooterActionButtonComponent } from './footer-action-button/footer-action-button.component';
import { FooterOptionButtonComponent } from './footer-option-button/footer-option-button.component';
import { ImportarButtonComponent } from './import-button/import-button.component';
import { NewButtonComponent } from './new-button/new-button.component';
import { OptionButtonComponent } from './option-button/option-button.component';
import { OptionChartButtonComponent } from './option-chart-button/option-chart-button.component';
import { TutorialButtonComponent } from './tutorial-button/tutorial-button.component';

@NgModule({
    imports: [SharedUIModule],
    declarations: [
        TutorialButtonComponent,
        ImportarButtonComponent,
        ActionButtonComponent,
        DangerButtonComponent,
        AddButtonComponent,
        OptionButtonComponent,
        OptionChartButtonComponent,
        NewButtonComponent,
        FooterOptionButtonComponent,
        FooterActionButtonComponent,
        CommentsOptionButtonComponent,
    ],
    exports: [
        TutorialButtonComponent,
        ImportarButtonComponent,
        ActionButtonComponent,
        DangerButtonComponent,
        AddButtonComponent,
        OptionButtonComponent,
        OptionChartButtonComponent,
        NewButtonComponent,
        FooterOptionButtonComponent,
        FooterActionButtonComponent,
        CommentsOptionButtonComponent,
    ],
})
export class ButtonsComponentsGestioModule {}
