import { Component, Input, OnInit } from '@angular/core';

import { FormControl } from '@angular/forms';
import { hasRequiredField } from '../../../app.functions';

@Component({
    selector: 'gestio-label',
    templateUrl: './label.component.html',
    styleUrls: ['./label.component.scss'],
})
export class LabelGestioComponent implements OnInit {
    @Input() label: string;
    @Input() help: string;
    @Input() widthHelp: string = '300px';
    @Input() showHelp: boolean = true;
    @Input() copyButton: boolean = true;
    @Input() control: FormControl;

    @Input() isValid: boolean = false;
    @Input() noMarginBottom: boolean = false;

    popoverVisible: boolean;

    showEvent = {
        name: 'mouseenter',
        delay: 70,
    };

    hideEvent = {
        name: 'mouseleave',
        delay: 70,
    };

    private _cachedCaptionLabel: string;
    private _lastLabel: string;

    constructor() {}

    ngOnInit() {}

    get _captionLabel(): string {
        if (this._lastLabel !== this.label) {
            // Atualiza o cache somente quando 'label' muda
            this._cachedCaptionLabel =
                this.control && hasRequiredField(this.control)
                    ? `${this.label.replace(/\*/g, ' ')} *`
                    : this.label;
            this._lastLabel = this.label;
        }
        return this._cachedCaptionLabel;
    }

    get sanitizedLabel(): string {
        if (!this.label) return '';

        return this.label
            .toLowerCase() // transforma em minúsculas
            .normalize('NFD') // separa letras dos acentos
            .replace(/[\u0300-\u036f]/g, '') // remove os acentos
            .replace(/[^a-z0-9]/g, ''); // remove espaços e caracteres especiais
    }

    togglePopover() {
        this.popoverVisible = !this.popoverVisible;
    }

    onContentReady(event) {
        var contentElement = event.component.content();
        contentElement.style.padding = '0px';
    }
}
