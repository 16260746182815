import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';

import { SelectboxDatasource } from '../../../../core/models/selectbox-datasource.model';

@Component({
    selector: 'gestio-selectbox-label',
    templateUrl: './selectbox-label.component.html',
    styleUrls: ['./selectbox-label.component.scss'],
})
export class SelectboxLabelGestioComponent implements OnInit {
    @Input() label: string = 'Selecione';
    @Input() dataSource: SelectboxDatasource[] = [];
    @Input() control: FormControl = new FormControl(null, []);
    @Input() placeholder: string = '';
    @Input() help: string = '';
    @Input() width: string = '100%';
    @Input() validationMessageMode: string = 'always';
    @Input() errorRequired: string = `Deve ser informado.`;
    @Input() noMarginBottom: boolean = false;
    @Input() showClearButton: boolean = true;
    @Input() layoutDirection: string = 'column';

    @Output() onSelectionChanged = new EventEmitter<any>();

    showDropDownButton: boolean = true;

    constructor() {}

    ngOnInit() {
        if (this.layoutDirection === 'row') {
            this.label = this.label + `:`;
        }
    }

    ngOnDestroy() {}

    ngOnChanges() {}

    isValid(): boolean {
        if (!this.control) {
            return false;
        }

        if (this.control.pristine && this.control.untouched) {
            return true;
        }

        return !this.control.invalid;
    }

    validationError(): any {
        if (!this.control) {
            return { message: null };
        }

        if (!this.control.errors) {
            return { message: null };
        } else {
            if (this.control.errors.required) {
                return { message: this.errorRequired };
            } else if (this.control.errors.minlength) {
                return {
                    message: `Deve possuir no mínimo ${this.control.errors.minlength.requiredLength} caracteres.`,
                };
            } else if (this.control.errors.maxlength) {
                return {
                    message: `Deve possuir no máximo ${this.control.errors.maxlength.requiredLength} caracteres.`,
                };
            } else {
                return { message: `Inválido.` };
            }
        }
    }

    actionSelectionChanged(e: any) {
        this.onSelectionChanged.emit(e);
    }
}
