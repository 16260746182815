import { NgModule } from '@angular/core';

import { DxDateBoxModule } from 'devextreme-angular';
import { SharedUIModule } from '../../shared-ui.module';
import { DateboxDisabledLabelGestioComponent } from './datebox-disabled-label/datebox-disabled-label.component';
import { DateboxLabelGestioComponent } from './datebox-label/datebox-label.component';

@NgModule({
    imports: [SharedUIModule, DxDateBoxModule],
    declarations: [
        DateboxLabelGestioComponent,
        DateboxDisabledLabelGestioComponent,
    ],
    exports: [DateboxLabelGestioComponent, DateboxDisabledLabelGestioComponent],
})
export class DateboxsComponentsGestioModule {}
