import { NgModule } from '@angular/core';
import { DxTagBoxModule } from 'devextreme-angular';
import { SharedUIModule } from '../../shared-ui.module';
import { TagboxDisabledLabelComponent } from './tagbox-disabled-label/tagbox-disabled-label.component';

@NgModule({
    imports: [SharedUIModule, DxTagBoxModule],
    declarations: [TagboxDisabledLabelComponent],
    exports: [TagboxDisabledLabelComponent],
})
export class TagboxComponentsGestioModule {}
