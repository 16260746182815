import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule, MatTooltipModule } from '@angular/material';
import {
    DxButtonModule,
    DxDataGridModule,
    DxFileUploaderModule,
    DxListModule,
    DxPopoverModule,
    DxPopupModule,
    DxProgressBarModule,
    DxScrollViewModule,
    DxTemplateModule,
    DxTooltipModule,
} from 'devextreme-angular';
import { LabelGestioModule } from './components/label/label.module';
import { CopyToClipboardModule } from './directives/copy-to-clipboard.module';

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        FormsModule,
        LabelGestioModule,
        DxListModule,
        DxScrollViewModule,
        DxPopoverModule,
        DxPopupModule,
        DxTooltipModule,
        DxProgressBarModule,
        DxButtonModule,
        DxFileUploaderModule,
        MatTooltipModule,
        MatButtonModule,
        DxTemplateModule,
        CopyToClipboardModule,
        DxDataGridModule,
    ],
    exports: [
        CommonModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        FormsModule,
        LabelGestioModule,
        DxListModule,
        DxScrollViewModule,
        DxPopoverModule,
        DxTooltipModule,
        DxProgressBarModule,
        DxFileUploaderModule,
        MatTooltipModule,
        DxButtonModule,
        MatButtonModule,
        DxTemplateModule,
        DxPopupModule,
        CopyToClipboardModule,
        DxDataGridModule,
    ],
})
export class SharedUIModule {}
