import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ButtonsComponentsGestioModule } from '../buttons/buttons-components.module';
import { MetricsGestioComponent } from './metrics/metrics.component';
import { SharedUIModule } from '../../shared-ui.module';

@NgModule({
    imports: [SharedUIModule],
    exports: [MetricsGestioComponent],
    declarations: [MetricsGestioComponent],
})
export class ChartComponentsGestioModule {}
