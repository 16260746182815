import { NgModule } from '@angular/core';
import { DxRadioGroupModule } from 'devextreme-angular';
import { SharedUIModule } from '../../shared-ui.module';
import { CmpRadioGroupLabelGestioComponent } from './radio-group-label/cmp-radio-group-label.component';

@NgModule({
    imports: [SharedUIModule, DxRadioGroupModule],
    exports: [CmpRadioGroupLabelGestioComponent],
    declarations: [CmpRadioGroupLabelGestioComponent],
})
export class RadioGroupComponentsGestioModule {}
