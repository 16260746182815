import { NgModule } from '@angular/core';
import { DxTabsModule } from 'devextreme-angular';
import { SharedUIModule } from '../../shared-ui.module';
import { TabsDashboardComponent } from './tabs-dashboard.component';
import { TabsComponent } from './tabs.component';

@NgModule({
    imports: [SharedUIModule, DxTabsModule],
    declarations: [TabsComponent, TabsDashboardComponent],
    entryComponents: [TabsComponent, TabsDashboardComponent],
    exports: [TabsComponent, TabsDashboardComponent],
})
export class TabsGestioModule {}
