import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { DxTooltipModule } from 'devextreme-angular';
import { CopyToClipboardModule } from '../../directives/copy-to-clipboard.module';
import { LabelGestioComponent } from './label.component';

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        CopyToClipboardModule,
        FlexLayoutModule,
        DxTooltipModule,
    ],
    declarations: [LabelGestioComponent],
    entryComponents: [LabelGestioComponent],
    exports: [LabelGestioComponent],
})
export class LabelGestioModule {}
