import { NgModule } from '@angular/core';
import { DxSwitchModule } from 'devextreme-angular';
import { SharedUIModule } from '../../shared-ui.module';
import { CmpSwitchLabelGestioComponent } from './switch-label/cmp-switch-label.component';

@NgModule({
    imports: [SharedUIModule, DxSwitchModule],
    exports: [CmpSwitchLabelGestioComponent],
    declarations: [CmpSwitchLabelGestioComponent],
})
export class SwitchesComponentsGestioModule {}
