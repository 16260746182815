import { NgModule } from '@angular/core';

import { SharedUIModule } from '../../shared-ui.module';
import { GestioTextboxSearchComponent } from './gestio-textbox-search.component';
import { DxTextBoxModule } from 'devextreme-angular';

@NgModule({
    imports: [SharedUIModule, DxTextBoxModule],
    declarations: [GestioTextboxSearchComponent],
    exports: [GestioTextboxSearchComponent],
})
export class TextboxSearchGestioModule {}
