import { NgModule } from '@angular/core';

import { DxDateBoxModule } from 'devextreme-angular';
import { SharedUIModule } from '../../shared-ui.module';
import { CmpDatagridFabToolbarGestioComponent } from './cmp-datagrid-fab-toolbar.component';
import { CmpDatagridFooterOptionsGestioComponent } from './cmp-datagrid-footer-options.component';
import { CmpDatagridFilterDateboxGestioComponent } from './datebox/datebox-label/cmp-datagrid-filter-datebox.component';
import { CmpDatagridFilterDateboxMonthYearLabelGestioComponent } from './datebox/datebox-month-year-label/cmp-datagrid-filter-datebox-month-year-label.component';
import { CmpDatagridFilterDateboxYearLabelGestioComponent } from './datebox/datebox-year-label/cmp-datagrid-filter-datebox-year-label.component';

@NgModule({
    imports: [SharedUIModule, DxDateBoxModule],
    exports: [
        CmpDatagridFabToolbarGestioComponent,
        CmpDatagridFooterOptionsGestioComponent,
        CmpDatagridFilterDateboxGestioComponent,
        CmpDatagridFilterDateboxMonthYearLabelGestioComponent,
        CmpDatagridFilterDateboxYearLabelGestioComponent,
    ],
    declarations: [
        CmpDatagridFabToolbarGestioComponent,
        CmpDatagridFooterOptionsGestioComponent,
        CmpDatagridFilterDateboxGestioComponent,
        CmpDatagridFilterDateboxMonthYearLabelGestioComponent,
        CmpDatagridFilterDateboxYearLabelGestioComponent,
    ],
})
export class DatagridComponentsGestioModule {}
