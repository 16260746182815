import { NgModule } from '@angular/core';
import { SharedUIModule } from '../../shared-ui.module';
import { TagboxComponentsGestioModule } from '../tagbox/tagbox-components.module';
import { CommentsComponent } from './comments.component';

@NgModule({
    imports: [SharedUIModule, TagboxComponentsGestioModule],
    declarations: [CommentsComponent],
    exports: [CommentsComponent],
})
export class CommentsComponentsGestioModule {}
