import { NgModule } from '@angular/core';
import { SharedUIModule } from '../../shared-ui.module';
import { CmpCheckboxLabelGestioComponent } from './checkbox-label/cmp-checkbox-label.component';
import { DxCheckBoxModule } from 'devextreme-angular';

@NgModule({
    imports: [SharedUIModule, DxCheckBoxModule],
    exports: [CmpCheckboxLabelGestioComponent],
    declarations: [CmpCheckboxLabelGestioComponent],
})
export class CheckboxesComponentsGestioModule {}
