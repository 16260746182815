import { NgModule } from '@angular/core';
import { SharedUIModule } from 'src/app/ui-components-gestio/shared-ui.module';
import { FormModalComponent } from './form-modal.component';

@NgModule({
    imports: [SharedUIModule],
    declarations: [FormModalComponent],
    exports: [FormModalComponent],
})
export class FormModalModule {}
