import { NgModule } from '@angular/core';
import { DxTextBoxModule } from 'devextreme-angular';
import { SharedUIModule } from '../../shared-ui.module';
import { TelefoneGestioComponent } from './telefone/telefone.component';
import { TextboxDisabledLabelGestioComponent } from './textbox-disabled-label/textbox-disabled-label.component';
import { TextboxLabelGestioComponent } from './textbox-label/textbox-label.component';

@NgModule({
    imports: [SharedUIModule, DxTextBoxModule],
    declarations: [
        TextboxLabelGestioComponent,
        TextboxDisabledLabelGestioComponent,
        TelefoneGestioComponent,
    ],
    exports: [
        TextboxLabelGestioComponent,
        TextboxDisabledLabelGestioComponent,
        TelefoneGestioComponent,
    ],
})
export class TextboxsComponentsGestioModule {}
