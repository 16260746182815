import { NgModule } from '@angular/core';

import { SharedUIModule } from '../../shared-ui.module';
import { SelectboxLabelGestioComponent } from './selectbox-label/selectbox-label.component';
import { DxSelectBoxModule } from 'devextreme-angular';

@NgModule({
    imports: [
        SharedUIModule,
        DxSelectBoxModule
    ],
    declarations: [SelectboxLabelGestioComponent],
    exports: [SelectboxLabelGestioComponent],
})
export class SelectboxsComponentsGestioModule {}
