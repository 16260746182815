import { NgModule } from '@angular/core';

import { DxTextBoxModule } from 'devextreme-angular';
import { SharedUIModule } from '../../shared-ui.module';
import { GestioTextboxGenerateProductCodeComponent } from './gestio-textbox-generate-product-code.component';

@NgModule({
    imports: [SharedUIModule, DxTextBoxModule],
    declarations: [GestioTextboxGenerateProductCodeComponent],
    exports: [GestioTextboxGenerateProductCodeComponent],
})
export class TextboxGenerateProductCodeGestioModule {}
