import { NgModule } from '@angular/core';
import { SharedUIModule } from '../../shared-ui.module';
import { FormModalModule } from '../modal/form/form-modal.module';
import { AttachFilesComponent } from './attach-files.component';
import { ButtonsComponentsGestioModule } from '../buttons/buttons-components.module';
import { TextareasComponentsGestioModule } from '../textarea/textareas-components.module';

@NgModule({
    imports: [
        SharedUIModule,
        ButtonsComponentsGestioModule,
        TextareasComponentsGestioModule,
        FormModalModule,
    ],
    declarations: [AttachFilesComponent],
    exports: [AttachFilesComponent],
})
export class AttachFilesModule {}
