import { NgModule } from '@angular/core';

import { CmpFooterBarGestioComponent } from './footer-bar/cmp-footer-bar.component';
import { SharedUIModule } from '../../shared-ui.module';

@NgModule({
    imports: [SharedUIModule],
    exports: [CmpFooterBarGestioComponent],
    declarations: [CmpFooterBarGestioComponent],
})
export class FooterComponentsGestioModule {}
