import { NgModule } from '@angular/core';
import { DxTextAreaModule } from 'devextreme-angular';
import { SharedUIModule } from '../../shared-ui.module';
import { TextareaLabelGestioComponent } from './textarea-label/textarea-label.component';

@NgModule({
    imports: [SharedUIModule, DxTextAreaModule],
    declarations: [TextareaLabelGestioComponent],
    exports: [TextareaLabelGestioComponent],
})
export class TextareasComponentsGestioModule {}
